import { Component } from 'react';
import axios from 'axios';
import "./blog.css";
import Navbar from "./pers-navbar.component";
import AddBlog from './edit-post.component';

const Blog = props => (
    
    <div className="blogpost">
        <div className="blogttl" >{props.blog.title}</div>
        <div className="blogdate">{(new Date(props.blog.date)).toLocaleString().substring(0, (new Date(props.blog.date)).toLocaleString().indexOf('/202')+5)}</div>
        {/* <p className="align"><div className="blogimgwrapper"><img className="blogimage" src={"data:image/jpeg;base64,"+(new Buffer.from(props.blog.image).toString("base64").toString("base64"))}   alt="blog"/></div></p> */}
        {props.blog.image!=="" ? <p className="align"><div className="blogimgwrapper"><img className="blogimage" src={props.blog.image}   alt="blog" /></div></p> : ""}
        {/* <texrea className="blogdetes" disabled rows="20">{props.blog.post}</textarea> */}
        <div className="blogdetes">{props.blog.post}</div>
    </div>

)

export default class BlogPosts extends Component {
    constructor(props) {
        super(props);
        // this.changeAlbum = this.changeAlbum.bind(this);
        this.pullPosts = this.pullPosts.bind(this);
        this.create = this.create.bind(this);
        this.close  = this.close.bind(this);
        localStorage.setItem('token', "64c3e4ba2e1a4945d870cda8")
        this.state = {
            posts: [],
            album: "Personal",
            lastevent: "",
            vd: Component,
        }

    }

    componentDidMount(){
        this.pullPosts();
    }

    close(){     
        this.setState({
            vd: Component,
        });
        this.pullPosts()
    }

    create(e){
        e.preventDefault()
       
        this.setState({
            dialog: !this.state.dialog,
            vd: <AddBlog close={this.close}/>
        })
        // this.showNotifications();
    }

    blogList() {
        return this.state.posts.sort(function(a, b) {
            return (a["date"] < b["date"]) ? 1 : ((a["date"] > b["date"]) ? -1 : 0);
          }).map(currentpost => {
            return <Blog blog={currentpost}/>
        });
    }

    pullPosts(){
        axios.get("https://adits-notesapp.herokuapp.com/blog/of/kikoguest/Personal")
                .then(response => {
                    var main = response.data;
                    this.setState({posts: main})
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                  //  window.location = "/access";
                });
        }


    render() {
        return (
            <div className="container-fluid" style={{"background-color":"#9BDCE6"}}>         
             {/* <Navbar />    */}
               <div className="blogtitle">See blog posts about my life below ↓</div>
               <div style={{'text-align': 'right','right': '50%'}}><input
                                    type="submit"
                                    value="Make a Post"
                                    className="bt1"
                                    
                                    onClick={this.create}
                                /></div>
               {this.state.vd}
                {this.blogList()}
                
                <h6>See my brother's birthday here: <a href="http://www.kavimehta.com">Kavi Mehta.com</a></h6>
            </div>
        )
    }
}