import { Component } from 'react';
import axios from 'axios';
import AddBlog from './edit-post.component';
import Navbar from "./admin-navbar.component";
import "./notes.css";

const Blog = props => (
    <tr className="blogpost">
        <td><span className="date">{(new Date(props.blog.date)).toLocaleString().substring(0, (new Date(props.blog.date)).toLocaleString().indexOf('/202'))}:</span> <span className="note"><strong>{props.blog.title}</strong></span></td>
        <td><button className="bt2" onClick={() => { props.hide(props.blog._id) }}>{props.blog.hide.toString().replace("false","Hide").replace("true","Unhide")}</button> | <button className="bt1" onClick={() => props.edit(props.blog._id)}>Edit</button></td>
    </tr>
)

export default class BlogPosts extends Component {
    constructor(props) {
        super(props);
        // this.changeAlbum = this.changeAlbum.bind(this);
        this.pullPosts = this.pullPosts.bind(this);
        this.hide = this.hide.bind(this);
        this.create = this.create.bind(this);
        this.close  = this.close.bind(this);
        this.edit = this.edit.bind(this);
        
        this.state = {
            posts: [],
            album: "Personal",
            lastevent: "",
            vd: Component,
            dialog: false,
        }

    }

    componentDidMount(){
        this.pullPosts();
    }

    close(){     
        this.setState({
            vd: Component,
        });
    }

    blogList() {
        return this.state.posts.map(currentpost => {
            return <Blog blog={currentpost} hide={this.hide} key={currentpost._id} edit={this.edit} />
        });
    }

    hide(id) {
        axios.get("https://adits-notesapp.herokuapp.com/blog/hide/" + id)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullPosts();
            }, 250));
    }

    //  changeAlbum(e) {
    //      console.log(this.state.album)
    //     if(this.state.lastevent !== "")
    //         this.state.lastevent.target.className = "circle";
    //     this.setState({
    //         album: e.target.name,
    //         lastevent: e
    //     });
    //     console.log(this.state.album)
    //     this.pullPosts(e.target.name);  
    //     e.target.className = "view";
    // }
    
    pullPosts(){
        axios.post("https://adits-notesapp.herokuapp.com/blog/secure/of/kikoguest/Personal",{owner: localStorage.getItem('token')})
                .then(response => {
                        this.setState({ posts: response.data });
                    })
                    .catch((error) => {
                        console.log(error);
                      //  window.location = "/access";
                    });
        }

    create(e){
        // const blog = {
        //     title: "New Blog",
        //     date: new Date(),
        //     owner: localStorage.getItem('token'),
        //     post: "Fill Post",
        //     tag: ["avrakuda"],
        //     album: "Personal",
        //     comment: this.state.comment,
        // };
        // axios.post("https://adits-notesapp.herokuapp.com/blog/add/", blog)
        // .then(res => setTimeout(() => { console.log(res.data); window.location = "/admin/blogs"; }, 250));
        e.preventDefault()
        this.setState({
            dialog: !this.state.dialog,
            vd: <AddBlog close={this.close}/>
        })
        // this.showNotifications();
    }

    edit(id){     
        this.setState({
            dialog: !this.state.dialog,
            vd: <AddBlog kid={id} close={this.close}/>
        })
    }

    render() {
        return (
            <div className="container-fluid" style={{"background-color":"#9BDCE6"}}>
                {/* <Navbar />             */}
                <title>Your Blogs</title>
                <h2 className="adBlogTitle" style={{color:"white"}}>Your Blog Posts</h2>
                {this.state.vd}
                <input
                                    type="submit"
                                    value="Create"
                                    className="bt1"
                                    onClick={this.create}
                                />
                {/* <table className="table table-responsive"> //not enogh time to make efficient
                    <thead>
                        <tr>
                            <td>
                                <button type="button" name="Personal" className="circle" onClick={this.changeAlbum}>Personal</button></td>
                                <td><button type="button" name="Professional" className="circle" onClick={this.changeAlbum}>Professional</button></td>
                
                        </tr>
                    </thead>
                </table> */}
                <table className="table table-lg table-responsive-lg  table-hover">
                <tbody>
               {this.blogList()}
                </tbody>
                </table>
    
            </div>
        )
    }
}